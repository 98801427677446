import './App.css';
//componentes
import {Navigation} from './components/Navigation/Navigation'
function App() {
  return (
    <div className="App text-center  p-0 w-100 h-100">
      <Navigation/>
    </div>
  );
}



export default App;
